import React from 'react';
import Card from './Card';
import './card.css';
import naviglio from './naviglio.jpg'
import pontetresa from './pontetresa.jpg'
import ceresole from './ceresole.jpg'
import lagoceresole from './lagoceresole.jpg'
import rggrande from './rggrande.jpg'
import cpescone from './cpescone.jpg'
import lagodorta from './lagodorta.jpg'
import dep from './dep.jpg'
import piccolini from './piccolini.jpg'
import vigenovese from './vigenovese.jpg'
import pompeiese from './pompeiese.jpg'
import inavigli from './inavigli.jpg'
import troggia from './troggia.jpg'
import mais from './mais.jpg'
import gerola from './gerola.jpg'
import riti from './666.jpg'
import azuccolo from './azuccolo.jpg'
import ospedale from './ospedale.jpg'
import madre from './madre.jpg'
import muline from './muline.jpg'
import biscia from './biscia.jpg'
import gravmist from './gravmist.jpg'
import misteriosa from './misteriosa.jpg'
import canalgrande from './canalgrande.jpg'
import sang from './sang.jpg'
import borgolavezzaro from './borgolavezzaro.jpg'
import albonese from './albonese.jpg'
import tandarei from './Tandarei.jpg'
import fucine from './fucine.jpg'
import papavera from './papavera.jpg'
import vigevano from './vigevano.jpg'
import spazzatura from './spazzatura.jpg'
import ardesio from './ardesio.jpg'
import morsella from './morsella.jpg'
import galati from './galati.jpg'
import giurgenidanubio from './giurgenidanubio.jpg'
import giurgeni from './giurgeni.jpg'
import ferrera from './ferrera.jpg'
import cilaest from './cilaest.jpg'
import guliver from './guliver.jpg'
import grave from './grave.jpg'
import cassolo from './cassolo.jpg'
import barbavara from './barbavara.jpg'
import oropa from './oropa.jpg'
import mortara from './mortara.jpg'
import arona from './arona.jpg'
import auswitz from './auswitz.jpg'
import casserena from './casserena.jpg'
import casenuoveprima from './casenuoveprima.jpg'
import madresenzaacqua from './madresenzaacqua.jpg'
import petrarca from './petrarca.jpg'
import gabicce from './gabicce.jpg'
import slobozia from './slobozia.jpg'
import istambul from './istambul.jpg'
import bucarest from './bucarest.jpg'
import silistr from './silistr.jpg'
import calarasi from './calarasi.jpg'
import biraga from './biraga.jpg'
import premia from './premia.jpg'
import cascatatoce from './cascatatoce.jpg'
import cascatavideo from './tocevideo.mp4'
import CardVideo from './Cardvideo';
import Braila from './braila.jpg';
import Cilalbonese from './cilalbonese.jpg'
import Cordu from './cordu.jpg'
import Novararurale from './novararurale.jpg';
import CentralPark from './centralpark.jpg'
import Ciclabiledue from './ciclabile2.jpg'
import Cadore from './cadore.jpg'
import Riseria from './riseria.jpg'
import Biandrate from './biandrate.jpg'
import Notturno from './notturno.mp4'
import Bereguardo from './bereguardo.webp'
import Valsesia from './valsesia.webp'
import ValsesiaVideo from './valsesia.mp4'
import NovarafOTO from './novararoggia.jpg'
import NovaraVideo from './novararoggia.mp4'
import parona2 from './parona2.jpg'
import gambolo from './gambolo.jpeg'
import genovavige from './genovavige.jpeg';
import cascadavideo1 from './cascada.mp4';
import cascadalotisor from './cascadalotisor.webp'
import rbm from './rmb.webp'
import artigianato from './artigianato.webp'


const CardContainer = () => {
  return (
    <div className="card-container">
      <Card
        imageSrc={rbm}
        title="Roggia Madre verso Borgolavezzaro"
        description="Eh la nostra cara roggia madre, il profilo ha compiuto 1 anno ma la roggia madre rimmarrà sempre nei nostri cuori. Da dove siamo partiti a fino a dove arriveremo"
        linkbutton= ""
      />
      <Card
        imageSrc={genovavige}
        title="Foto di vigevano in corso genova la seconda"
        description="leggi roggia corso genova "
        linkbutton= ""
      />
      <Card
        imageSrc={cascadalotisor}
        title="Cascada Lotisor in romania"
        description="Ultima foto della serie delle cascate in Romania vicino a Sibiu. "
        linkbutton= ""
      />
      <CardVideo
        videoSrc={cascadavideo1}
        title="Cascata Lotisor in Romania"
        description="leggi cascada lotisor"
        linkbutton= ""
      />
      <Card
        imageSrc={gambolo}
        title="Roggia di gambolo"
        description="Prima foto roggia sul suolo di gambolò, molto bella dal punto di vista panoramico"
        linkbutton= ""
      />
      <Card
        imageSrc={artigianato}
        title="Roggia a cilavegna in Viale Artigianato"
        description="Roggia in viale Artigianato"
        linkbutton= ""
      />
      <Card
        imageSrc={parona2}
        title="Roggia di parona"
        description="Questa roggia attraversa i campi vicino al termovalorizzatore di parona, che grazie all'acqua irriga i campi della zona "
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
      <Card
        imageSrc={NovarafOTO}
        title="Roggia di Novara"
        description="Leggi sotto "
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
      <CardVideo
        videoSrc={NovaraVideo}
        title="Il video della roggia di Novara"
        description="Questa roggia è una tra le più belle di novara ed è situata vicino al centro commerciale"
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
      <CardVideo
        videoSrc={ValsesiaVideo}
        title="Cascata in Valsesia"
        description="Questa è la quinta foto delle cascate, con la sorpresa che questa è una delle più belle in Italia "
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
      <Card
        imageSrc={Valsesia}
        title="Cascata in Valsesia"
        description="Questa è la quinta foto delle cascate, con la sorpresa che questa è una delle più belle in Italia "
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
      <Card
        imageSrc={Bereguardo}
        title="Ponte delle barche sul Ticino a Bereguardo"
        description="Questo ponte è uno dei più unici che attraversa il ticino, composto da una passerella in legno sollevata da delle barche. Inoltre vi è anche la prospettiva del fiume"
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
      <Card
        imageSrc={Biandrate}
        title="Canale Cavour a Biandrate (NO)"
        description="Questo è il Canale Cavour, uno dei primi canali artificiali costruiti in Italia che trae origine dal Po e si getta nel Ticino a Galliate. Inolltre il canale è stato costruito nell'epoca di Camillo Benso Conte di Cavour ed è il terzo canale artificiale più lungo d'Italia. Questa foto rappresenta il canale a Biandrate, uno dei punti a metà strada tra l'inizio e la fine di questo canale."
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
      <CardVideo
        videoSrc={Notturno}
        title="Roggia Notturna in formato video"
        description="I video e i sounds della roggia notturna, (qui in basso per ulteriori dettagli)."
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
      <Card
        imageSrc={Riseria}
        title="Roggia notturna di Cilavegna in via Riseria"
        description="Una splendida roggia, che appare dopo il buio della notte, illuminata dai lampioni in via riseria"
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
      <Card
        imageSrc={Cadore}
        title="Roggia di Cadore"
        description="Questa è la roggia di Cadore, in provincia di bolzano (BZ)"
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
      <Card
        imageSrc={Ciclabiledue}
        title="Roggia Trascurata della ciclabile di gravellona"
        description="Questa è la roggia trascurata della cicclabile di gravella"
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
       <Card
        imageSrc={CentralPark}
        title="Roggia del Central Park a New Work"
        description="Questa è la prima foro roggia fatta fuori dal continente europeo, tra l'altro in uno dei posti più famosi al mondo."
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
      <Card
        imageSrc={Novararurale}
        title="Roggia della Novara rurale"
        description="Questa è la roggia della Novara rurale, una tra le più belle nella provincia di Novara."
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
      <Card
        imageSrc={Cordu}
        title="Roggia di Cordu nel distretto di Valcea in Romania"
        description="Questa è la roggia di Cordu nel distretto di Valcea in Romania, tra la catena dei Carpazi."
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
      <Card
        imageSrc={Cilalbonese}
        title="Roggia tra Albonese e Cilavegna"
        description="Questa è una delle rogge più belle della zona, ma mai come l'autenticissima roggia madre."
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
      <Card
        imageSrc={Braila}
        title="Roggia a Brăila: una città affascinante sulla riva del Danubio"
        description="Brăila è una città situata nella regione di Muntenia, nella Romania orientale, ed è un porto sul Danubio e la capitale della contea di Brăila. La città si trova sulla riva sinistra del Danubio, a circa 20 chilometri da Galați. La contea di Brăila si trova nella parte orientale del paese, nell’unità chiamata Câmpia Română. Con la sua superficie di 4765,8 km², rappresenta il 2% della superficie del paese ed è una contea di medie dimensioni. La città di Brăila è la sede della contea, situata a 200 km a nord-est rispetto a Bucarest."
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
       <CardVideo
        videoSrc={cascatavideo}
        title="Cascata del Toce"
        description="Il primo video di roggia fanpage, è una delle cascate più belle di tutto il Nord Italia."
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
       <Card
        imageSrc={cascatatoce}
        title="Cascata del Toce"
        description="Meravigliosa la foto della cascata del Toce, una delle più belle da visitare in tutto quanto il nord Italia."
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
      <Card
        imageSrc={premia}
        title="Mini roggia a Premia (VB)"
        description="Mini roggio a Premia (VB), con l'acqua che cola dalle montagne"
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
      <Card
        imageSrc={biraga}
        title="La roggia Biraga a Ponzana (NO)"
        description="La spettacolare roggia Biraga a Ponzana (NO)"
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
      <Card
        imageSrc={calarasi}
        title="Roggia di Calarasi"
        description="Roggia armoniosa derivante dal Danubio a Calarasi in Romania"
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
      <Card
        imageSrc={silistr}
        title="Ep.3 speciale Danubio con in sfondo la Bulgaria"
        description="Il profilo sempre più international dopo la Romania, la Tuchia e l'Italia, ora la Bulgaria. in sfondo al danubio la città di Silistra. "
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
      <Card
        imageSrc={bucarest}
        title="La roggia di Bucarest"
        description="Dopo Istanbul, Bucarest, la foto roggia scattata nella capitale amministrativa rumena. Molto bella da visitare! "
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
      <Card
        imageSrc={istambul}
        title="La roggia di Istanbul in Turchia"
        description="Questa foto è stata scattata a Istanbul, Città più popolosa nonchè capitale economica della Turchia. Tra tradizioni e mix di culture e sstoria impressionante Istanbukl, è una delle città più affascinanti al mondo. "
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
      <Card
        imageSrc={slobozia}
        title="La roggia rumena a Slobozia"
        description="Questa roggia si trova a Slobozia, cittadina e capoluogo del distretto di Ialomita. Ottima l'ambientazione e la ripresa della foto."
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
      <Card
        imageSrc={gabicce}
        title="Il canale portuale di Gabicce Mare (PU)"
        description="Questo è il canale portuale di Gabicce Mare, una cittadina delle Marche al confine con l'Emilia Romagna. Offre un porto stupendo ed una spiaggia con acqua cristallina. Ottima da visitare"
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
      <Card
        imageSrc={petrarca}
        title="Roggia di Via F.Petrarca"
        description="Questa foto roggia è stata scattata in via F.Petrarca, nel comune natale di Roggia Fanpage, avente un ottima qualità ed un ottima ambientazione."
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
      <Card
        imageSrc={giurgeni}
        title="Roggia di Giurgeni"
        description="Questa straordinaria immagine cattura l'incanto di una roggia a Giurgeni, un pittoresco comune rumeno affacciato sulle acque del Danubio. La scena è resa ancora più magica dai riverberi dorati del sole che danzano sulla superficie dell'acqua, regalando uno spettacolo di riflessi mozzafiato. Un'istantanea che cattura la bellezza naturale e l'atmosfera suggestiva di questo luogo incantato."
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
      <Card
        imageSrc={giurgenidanubio}
        title="Speciale Danubio a Giugeni"
        description="Benvenuti a Giurgeni, dove il maestoso Danubio svela la sua magia in un contesto straordinario. Questa località, situata al confine con il distretto di Costanza, ci offre un panorama mozzafiato prima di immergersi nel suggestivo delta del fiume. Non dimentichiamoci che il Danubio è il secondo fiume più lungo e ampio in Europa, portando con sé una storia e una grandezza che si riflettono in ogni suo tratto. Con la sua bellezza ineguagliabile e la sua importanza culturale, il Danubio e Giurgeni ci invitano a esplorare e ad ammirare la natura e la grandiosità di questo luogo straordinario."
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
      <Card
        imageSrc={galati}
        title="Speciale Danubio a Galati"
        description="E Bene si, Ecco lo speciale Danubio a Galati, non molto lontano dal confine con l'Ucraina 🇺🇦, tra l'altro da qui distano solo 5km il luogo dove ha subito un forte bombardamento da parte dei russi il lunedí alle 3:00. Ricordiamoci noi di roggia fanpage condanniamo le azioni provenienti dalla Russia e Supportiamo (anche questa foto vicino al confine è una prova del supporto) all'Ucraina."
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
      <Card
        imageSrc={morsella}
        title="Roggia di morsella"
        description="Questa roggia possiede un carattere unico, in quanto fa parte di una delle frazioni di questa cittadina, conosciuta per la sua specializzazione nell'arte delle calzature. La sua presenza si intreccia in modo affascinante con la maestria artigianale locale, unendosi in una simbiosi di bellezza naturale e competenza manuale. Un connubio che rende questa roggia davvero straordinaria e rappresenta un tributo al talento e all'ingegno di questa comunità."
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
      <Card
        imageSrc={tandarei}
        title="Roggia Rumena a Tandarei"
        description="
        Ecco finalmente l'inizio della nuova serie di foto di rogge, questa volta spostandoci oltre i confini dell'Italia. La nostra prima tappa ci conduce in Romania, precisamente a Tandarei, una piccola ma accogliente città dotata di tutti i servizi essenziali per la sua popolazione. Con questa nuova aggiunta, inauguriamo un viaggio che si preannuncia avvincente e sorprendente. Questo è soltanto il primo passo, ma ho fiducia che l'intera serie sarà in grado di catturare la vostra attenzione e ispirare nuove prospettive sulle rogge nel mondo. Non vedo l'ora di condividere con voi ulteriori scoperte e avventure attraverso queste straordinarie immagini."
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
      <Card
        imageSrc={albonese}
        title="Roggia di Albonese"
        description="Questa affascinante immagine di una roggia è stata catturata ad Albonese, un incantevole borgo situato nella provincia di Pavia. Il nostro percorso ci ha guidato attraverso un pittoresco ponte che sovrasta questa roggia, lasciando un segno indelebile nel nostro viaggio. Questa esperienza ci ha ispirati a continuare la nostra esplorazione lungo le prossime rogge di transito, in cerca di nuovi panorami e storie da scoprire. Restate sintonizzati per ulteriori avventure e scoperte affascinanti!"
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
      <Card
        imageSrc={borgolavezzaro}
        title="Roggia di Borgolavezzaro"
        description="
        Questa suggestiva immagine di una roggia è stata catturata a Borgolavezzaro, un pittoresco comune situato nella provincia di Novara. Questo luogo è diventato noto per le sue affascinanti rogge che attraversano l'intera area, contribuendo in modo significativo alla crescita rigogliosa delle coltivazioni circostanti. Queste preziose vie d'acqua hanno dato vita a un paesaggio unico e fecondo, conferendo a Borgolavezzaro la sua fama caratteristica. È affascinante osservare come le rogge abbiano plasmato la vita di questa comunità, facilitando la crescita delle coltivazioni e regalando una bellezza naturale senza pari."
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
      <Card
        imageSrc={pontetresa}
        title="Lago di Lugano"
        description="Questo meraviglioso lago, determina parzialmente il confine tra l'Italia e la Svizzera in parte in provincia di Varese ed in parte in provincia di Como. Questa fotografia viene scattata a Ponte Tresa, un comune di confine, diviso in due parti, tra la Svizzera e l'Italia dal torrente Tresa, ed ogniuna ha due organizzazioni comunali diversi (Ponte Tresa Uff. Lavena Ponte Tresa, in Italia, municipio Ponte Tresa. Ponte Tresa Svizzera sede comunale in svizzera. La foto del lago è magnifica e consiglio questo posto a chi piace passeggare nel lungolago."
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
      <Card
        imageSrc={naviglio}
        title="Naviglio Grande"
        description="Scopriamo insieme il Naviglio Grande ad Abbiategrasso, un affascinante capitolo della storia ricco di tradizioni. Questo canale ha avuto un ruolo fondamentale nel trasporto di sabbia prelevata dai letti del fiume Ticino a Milano. Il Naviglio Grande ha le sue origini a Pavia, dove si snoda dal fiume Ticino, per poi attraversare la città di Milano fino al quartiere Ticinese. Un passaggio significativo è la Darsena, un'ampia area dove la sabbia prelevata dal Ticino veniva depositata. Per gestire il flusso delle acque, venne creato il Naviglio Grande, che proprio ad Abbiategrasso si biforca in due diramazioni: la divisione di Bereguardo (come mostrato in questa foto) e la divisione di Turbigo, che restituiva le acque al Ticino. Questa narrazione ci trasporta nell'epoca passata e ci permette di apprezzare il patrimonio storico e idraulico di questa regione."
        linkbutton="https://www.google.it/maps/place/Alzaia+Naviglio+Grande,+Abbiategrasso+MI/@45.3958471,8.9414347,14.5z/data=!4m6!3m5!1s0x4786e58849ef6c0d:0x8ce872b6991ab9ce!8m2!3d45.3999132!4d8.9401864!16s%2Fg%2F1v62gjr2?entry=ttu"
      />
      <Card
      imageSrc={ceresole}
      title="Cascata del Dres a Ceresole Reale"
      description="Questa straordinaria cascata, incastonata nella suggestiva Valle Orco del Piemonte, è un vero spettacolo naturale da ammirare. La sua maestosità si rivela a Ceresole Reale, un comune montano circondato dalle imponenti Alpi Graie. Questa località è celebre non solo per le sue cascate mozzafiato, ma anche per la conservazione intatta delle tradizioni autentiche. L'incanto della cascata si fonde con l'atmosfera autentica di Ceresole Reale, creando un'esperienza unica che cattura sia gli occhi che il cuore. Questo luogo rappresenta una preziosa testimonianza della bellezza naturale e culturale di quest'area, un luogo dove la natura e le tradizioni si fondono in armonia." 
      />
      <Card
      imageSrc={lagoceresole}
      title="Lago di Ceresole Reale"
      description="Questo affascinante lago, incastonato nella splendida Valle Orco del Piemonte, è un vero gioiello naturale da esplorare. La sua bellezza si manifesta a Ceresole Reale, un incantevole comune di montagna circondato dalle imponenti Alpi Graie. Oltre ad essere rinomato per le sue spettacolari cascate, Ceresole Reale si distingue anche per la sua dedizione nel preservare tradizioni autentiche. La magnificenza del lago si fonde con l'atmosfera autentica di Ceresole Reale, offrendo un'esperienza che affascina sia i sensi che l'anima. Questo luogo rappresenta un autentico connubio tra la maestosità naturale e la ricchezza culturale della regione, un luogo dove la natura e la tradizione si intrecciano in modo unico." 
      />
      <Card
      imageSrc={rggrande}
      title="Canale Irrigatorio Dirramazione Quintino Sella"
      description="Questo canale irrigatorio, sotto la gestione delle aziende di irrigazione Est Sesia, rappresenta uno dei più ampi e rilevanti canali di irrigazione nell'area Est Sesia. Il suo percorso inizia da Cameri (NO) e si estende fino a Novara, dove si ingrandisce notevolmente unendosi ad altri canali di dimensioni minori. Una trasformazione significativa avviene quando giunge nella Lomellina, in particolare a Cilavegna (Sant'Anna), dove si biforca in due diramazioni più piccole. Questi sottocanali continuano il loro percorso, raggiungendo infine le città di Pavia e Mortara. L'istantanea è stata catturata a Cilavegna, prima della divisione, sul ponticello della ciclabile. La qualità della foto riflette meravigliosamente l'atmosfera e la bellezza di questo scenario." 
      />
      <Card
      imageSrc={cpescone}
      title="Cascata del Pescone"
      description="Questa spettacolare cascata, situata nella parte orientale del Piemonte, si posiziona con grazia tra il lago Maggiore e il lago d'Orta. La sua incantevole location è ad Agrano (VB), offrendo un'esperienza affascinante e stimolante per gli amanti delle escursioni. Questo luogo non solo permette di godere della bellezza naturale circostante, ma offre anche l'opportunità di approfondire ulteriormente la propria conoscenza. L'intersezione tra la potenza delle acque e l'esplorazione dell'ambiente rendono questa cascata una meta perfetta per chi desidera avventure coinvolgenti e momenti di riflessione. Inoltre, la sua vicinanza ai laghi Maggiore e d'Orta aggiunge un tocco di magia a questa destinazione, invitando a una scoperta ancora più ampia della meravigliosa regione piemontese." 
      />
      <Card
      imageSrc={lagodorta}
      title="Isola San Giulio e lago d'Orta"
      description="Questo incantevole lago, seppur più piccolo rispetto ai maestosi laghi glaciali delle Alpi, possiede una caratteristica unica: la sua dimensione compatta. È proprio questa sua piccolezza a conferirgli un fascino singolare e inconfondibile. Sul versante orientale, il lago si apre verso Orta San Giulio, uno dei più suggestivi borghi da esplorare in Italia. Un aspetto notevole è la possibilità di visitare l'isola che si trova al centro del lago, con un costo di soli 5€ per l'imbarcazione. Questa isola, anch'essa di dimensioni ridotte, offre un'esperienza davvero affascinante e ricca di interesse. Nonostante le dimensioni contenute, questo lago offre un mondo di scoperte e sensazioni uniche, rendendo ogni visita un'esperienza straordinaria." 
      />
      <Card
      imageSrc={dep}
      title="Fuoriuscita Depuratore Dir.Quintino Sella "
      description="Vedi Diramazione Quintino Sella" 
      />
      <Card
      imageSrc={piccolini}
      title="Roggia Piccolinese "
      description="La piccola roggia, nota come Roggia Piccolinese, attraversa la pittoresca frazione di Vigevano Piccolini. Un'opportunità affascinante per gli osservatori è rappresentata dal ponticello situato nelle vicinanze del carcere, da cui è possibile ammirare questa affluente." 
      />
      <Card
      imageSrc={vigenovese}
      title="Roggia Vigenovese "
      description="
      Il nome di questa roggia è un'accattivante fusione tra il corso d'acqua principale, Genova, e la caratteristica località di Vigevano. Questa roggia ha un'autenticità tutta sua, sebbene mai paragonabile all'importanza della rinomata Roggia Madre. " 
      />
      <Card
      imageSrc={pompeiese}
      title="Roggia Pompeiese "
      description="Questa roggia invece si trova sempre a vigevano, ma vicino alla via con il nome della città costruta dai romani, che venne distrutta dal Vesuvio nel 79 d.c" 
      />
      <Card
      imageSrc={gerola}
      title="Ponte sul Po della Gerola "
      description="Questa foto sdel po è stata scattata sul ponte della Gerola (a Sanlazzaro de' Burgondi) in provincia di Pavia. Spettacolare la vista." 
      />
      <Card
      imageSrc={inavigli}
      title="Naviglio Grande Notturno "
      description="Leggi Naviglio Grande" 
      />
      <Card
      imageSrc={troggia}
      title="Foto Roggia con tramonto "
      description="Questa roggia è stata scattata con il tramonto vicino a Gravellona Lomellina (PV)" 
      />
      <Card
      imageSrc={azuccolo}
      title="Roggia dell'agenzia Zuccolo "
      description="Questa è la roggia che attraversa i sotterranei dell'agenzia Zuccolo, ed esce in superficie attraversando la strada Provinciale" 
      />
      <Card
      imageSrc={riti}
      title="Roggia Dei Riti Satanici "
      description="Questa roggia attraversa un pozzo, che potrebbe essere utilizzato per i riti satanici (666)" 
      />
      <Card
      imageSrc={ospedale}
      title="Roggia Ospedaliera"
      description="Questa Roggia Attraversa l'Ospedale di vigevano,ed anche i sotterranei della città" 
      />
      <Card
      imageSrc={mais}
      title="Lago Maggiore nsu vista su Arona, con una Saetta in cielo"
      description="Il Lago Maggiore si erge come uno dei laghi glaciali più vasti per estensione. La sua superficie si estende prevalentemente in Italia, dividendo le regioni del Piemonte e della Lombardia, e in parte si estende in Svizzera nel cantone Ticino. Il fiume Ticino gioca il duplice ruolo di immissario ed emissario del lago, collaborando con altri affluenti minori come il Toce. L'istantanea è stata catturata ad Angera, offrendo uno sguardo verso Arona dalla prospettiva lombarda del lago." 
      />
      <Card
      imageSrc={biscia}
      title="Biscia Morta"
      description="Non c'entra con le rogge, ma comunque ha fatto parte di una delle nnostre avventure vicino alle roggia, quindi questo esemplare morto va premiato." 
      />
      <Card
      imageSrc={muline}
      title="La roggia con il piccolo mulino a Gravellona Notturna (Nome: Roggia del mulinello)"
      description="Questa roggia attraversa Gravellona, sia nel sottosuolo, sia esternamente, come in questo caso che attraversa il mulino vicino al Parco dei Tre Laghi. Questa fotto è stata scattata nella notte della più grande festa di Gravellona." 
      />
      <Card
      imageSrc={misteriosa}
      title="Roggia Misteriosa (Nome: Roggia Lacustre)"
      description="Questa roggia non ha una descizione pk è misteriosa" 
      />
      <Card
      imageSrc={gravmist}
      title="Roggia Gravellonese (Nome: Roggia delle tubature)"
      description="Questa roggia attraversea le campagne di gravellona, permenttendo così di irrigarli" 
      />
      <Card
      imageSrc={sang}
      title="Roggia di San Giorgio Lomellina"
      description="Questa roggia invece si triova vicino a San Giorgio Lomellina, un piccolo comune della parte Sud della Lomellina" 
      />
      <Card
      imageSrc={canalgrande}
      title="Canal Grande"
      description="
      Il Canal Grande di Venezia, il maestoso cuore acquatico della città, si distingue come il canale più ampio e profondo dell'intero complesso lagunare. La sua storia è avvolta in un fascino unico: inizialmente, nessun ponte attraversava le sue acque fluide. Tuttavia, l'arrivo del celebre Ponte di Rialto segnò un punto di svolta, conferendogli l'onore di essere il primo ponte a varcare con successo il Canal Grande. Questa monumentale struttura ha collegato le sponde del canale, aprendo nuove vie di connessione e trasporto, e diventando rapidamente un'icona dell'architettura veneziana. Il Canal Grande, incorniciato dalle sontuose facciate delle affascinanti abitazioni e dalle eleganti imbarcazioni che vi solcano, continua a intrappolare l'immaginazione dei visitatori di tutto il mondo." 
      />
      <Card
      imageSrc={fucine}
      title="Roggia del parco delle Fucine"
      description="Questa foto è stata scattata al parco delle Fucine, in proviincia di Brescia in un meraviiglioso parco naturale" 
      />
      <Card
      imageSrc={papavera}
      title="Roggia Gravellonese con flora Papavera"
      description="Questa foto è stata scattata a Gravellona, nella roggia degli affronti, vitsa l'avventura reportata, con flora papavera" 
      />
      <Card
      imageSrc={spazzatura}
      title="Roggia Cilavegnese con spazzatura"
      description="Questa foto è stata scattata, per consapevolizzare la gente a smaltire i rifiuti e non abbandonarli nell'ambiente." 
      />
      <Card
      imageSrc={ardesio}
      title="Fiume Serio ad Ardesio (BG)"
      description="Questa fotografia ci trasporta ad Ardesio, una pittoresca località situata nella provincia di Bergamo. Qui, l'elemento protagonista è un vivace torrente che scorre attraverso il paesaggio. Sebbene ancora caratterizzato come torrente, questo corpo d'acqua offre uno spettacolo di movimento e vitalità, arricchendo il contesto naturale circostante. L'immagine cattura la suggestiva bellezza di un momento fugace, invitandoci a contemplare la forza e la serenità dell'acqua in un angolo affascinante della provincia di Bergamo." 
      />
      <Card
      imageSrc={vigevano}
      title="Roggia Vigevanese"
      description="La città specializzata in calzature ed ovviamente in rogge." 
      />
      <Card
        imageSrc={ferrera}
        title="Cascata di Ferrera di Varese"
        description="Questa cascata si trova in provincia di Varese, non molto lontando dal Lago Maggiore e da Varese stesso, esattamente a ferrera di Varese. Ne vale la pena visitarlo, visto che è una delle cascate più belle dell'intera Lombardia"
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
      <Card
        imageSrc={guliver}
        title="Roggia Guliver"
        description="Questa bellisssima roggia si trova a cilavegna, dov'è nata l'inizativa del profilo e dove si trovano altrettante belle rogge"
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
      <Card
        imageSrc={cilaest}
        title="Roggia dell'Est di Cilavegna"
        description="Questa Roggia si trova verso gravella, rappresentando il punto da cui sorge il sole a Cila."
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
      <Card
        imageSrc={grave}
        title="Roggia di Gravella Casalinga"
        description="Questa bellisssima roggia si trova a Gravellona Lomellina, un Comune della provincia di pavia dove l'arte è tradizione e lo sono anche le rogge"
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
      <Card
        imageSrc={cassolo}
        title="Roggia Cassolese"
        description="Il comune di Cassolnovo, olre ad essere bello, ci riserva alcune importanti e segrete rogge, tra cui questa."
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
      <Card
        imageSrc={barbavara}
        title="Roggia delle Cascine Barbavara"
        description="Queta frazione del paese dell'Arte ci riserva anche lei importanti e bellissime rogge, che permettono l'irrigazione dei campi, e quindi degli alimenti buoni, sani e biologici. Forza Roggia!!"
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
      <Card
        imageSrc={oropa}
        title="Roggia di Oropa di Biella"
        description="Questa foto del ruscello viene scattata a Oropa (BI), non molto distante dalla Valle d'Aosta, trovandosi nella catena montuosa più alta d'Europa"
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
      <Card
        imageSrc={mortara}
        title="Roggia di Mortara"
        description="Questa Roggia, si trova a Mortara, importante cittadina che si trova sull'antica Via Francigena ed importante teatro di morte per gli scontri avvenuti."
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
      <Card
        imageSrc={arona}
        title="Roggia di Arona"
        description="Anche lungo il Lago Maggiore si possono trovare rogge come questa, esattamente ad Arona."
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
      <Card
        imageSrc={auswitz}
        title="Aucshwitz d'Italia"
        description="Benvenuti ad Aucshwitz, ma a Barbavara, in una cascina Abbandonata."
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
      <Card
        imageSrc={casserena}
        title="Roggia Retro Casa Serena"
        description="Questa roggia qui, delimita il confine occidentale ci Cilavegna, guardando il punto dove il sole tramonta."
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
      <Card
        imageSrc={casenuoveprima}
        title="Roggia di Case Nuove Senza l'Acqua"
        description="Questa è la Terza foto del profilo, e viene illustrata la roggia di case nuove senza l'acqua"
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
      <Card
        imageSrc={madresenzaacqua}
        title="Roggia madre di Febbraio"
        description="Anche senza l'acqua la roggia madre è sempre una roggia (Febbraio 2023)"
        linkbutton= "https://www.google.it/maps/place/21037+Lavena+Ponte+Tresa+VA/@45.9633011,8.8510939,15z/data=!3m1!4b1!4m6!3m5!1s0x4785d338608ab029:0x83d581dcd83319cf!8m2!3d45.9564617!4d8.8725744!16zL20vMGdoejZk?entry=ttu"
      />
      <Card
      imageSrc={madre}
      title="La roggia Madre"
      description="Ebbene si, lei è stata la prima roggia fotografata del profilo e da cui si è presa isprirazione a farlo. Ricordiamoci il detto 'Roggia Artigiani della Qualità 😉'" 
      />
    </div>
  );
};

export default CardContainer;