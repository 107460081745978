import React from "react";
const Countdown = () => {
  
    return (
        <div style={{ left: 0, width: '100%', height: 0, position: 'relative', paddingBottom: '25%', margin: '0 auto' }}>
        <iframe
          src="https://www.tickcounter.com/widget/countdown/4386088"
          style={{ top: 0, left: 0, width: '100%', height: '100%', position: 'absolute', border: 0, overflow: 'hidden' }}
          title="Evento Live Novità Roggia"
        ></iframe>
      </div>
    );
  };

export default Countdown;