import React from 'react';

class MyComponent extends React.Component {
  componentDidMount() {
    const script = document.createElement('script');

    script.text = `!function () { if (window.t4hto4) console.log("WiserNotify pixel installed multiple time in this page"); else { window.t4hto4 = !0; var t = document, e = window, n = function () { var e = t.createElement("script"); e.type = "text/javascript", e.async = !0, e.src = "https://pt.wisernotify.com/pixel.js?ti=jqy0jlmjjnllj", document.body.appendChild(e) }; "complete" === t.readyState ? n() : window.attachEvent ? e.attachEvent("onload", n) : e.addEventListener("load", n, !1) } }();`;

    document.body.appendChild(script);
  }

  render() {
    return <div>Il tuo contenuto qui</div>;
  }
}

export default MyComponent;


