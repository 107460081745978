import React from 'react';
import Button from 'react-bootstrap/Button';

const ContestButton = () => {
  const handleClick = () => {
    window.location.href = '/contest';
  }

  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <h2 className="text-center">🎉 Unisciti al nostro emozionante contest! 🎉</h2>
      <p className="text-center">Non perdere l'opportunità di mostrare il tuo talento e vincere premi fantastici. Clicca sul pulsante qui sotto per saperne di più!</p>
      <Button variant="primary" onClick={handleClick}>
        Scopri di più
      </Button>
    </div>
  );
}

export default ContestButton;

