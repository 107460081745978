import React from "react";

function EndHome () {
    return (
        <div className="End" style={{backgroundColor: 'black', textDecoration: 'white'}}>
            <h5 style={{textAlign: 'center', color: 'white'}}>Questo è il sito web ufficiale di roggia fanpage</h5>
            <p style={{color: 'white', borderBottom: '20px'}}>Copyright © 2023/Roggia Fanpage, tutti i diritti riservati</p>

        </div>
    );
}

export default EndHome;