import React from "react";
import './Content.css'; // Importa il file CSS per lo stile del componente

function Content () {
  return (
    <div className="container">
      <h1>Roggia Fanpage</h1>
     <h2> Esplora Rogge e Corsi d'Acqua su Roggia Fanpage: Scopri il Fascino delle Acque Dolci! </h2>
    </div>
    
  );
}

export default Content;