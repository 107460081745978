import React from "react";

function Ebody () {
    return(
        <div className='ebody'>
        <h2>La storia e la nascita del profilo</h2>
        <h4>Il nostro profilo vanta una lunga e affascinante storia, tessuta insieme da passioni e curiosità. La nostra dedizione alle rogge è stata il motore propulsore che ha dato vita a Roggia Fanpage, un luogo unico e ricco di creatività, dove trovano spazio le meraviglie delle rogge e dei corsi d'acqua. <br />

All'inizio, il nostro focus era esclusivamente sulla Roggia Madre, ma la nostra visione si è allargata, abbracciando le molteplici rogge sia in Italia che all'Estero. Ogni giorno, assistiamo a una crescita significativa del nostro profilo, grazie all'impegno costante e appassionato da parte nostra e al vostro prezioso supporto. < br />

Insieme, costruiamo un luogo dove l'amore per le rogge si intreccia con la condivisione di conoscenze e bellezze, creando una comunità in costante crescita. Siamo grati per l'opportunità di condividere questa avventura con voi e non vediamo l'ora di continuare a far crescere questa passione insieme.</h4>
      </div>
    );
}

export default Ebody;