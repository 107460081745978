import React from 'react';
import ReactPlayer from 'react-player';

const CardVideo = ({ title, description, linkbutton, videoSrc }) => {
  return (
    <div className="card custom-card-first">
      <div className="card-img-top">
        <ReactPlayer url={videoSrc} controls={true} width="100%" height="100%" />
      </div>
      <div className="card-body">
        <h5 className="card-title">{title}</h5>
        <p className="card-text">{description}</p>
        <a href={linkbutton} className="my-button">
          Vedi Luogo in Google Maps
        </a>
      </div>
    </div>
  );
};

export default CardVideo;
