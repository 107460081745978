import React from "react";

function Proposta () {
  return (
    <div className="container">
  <div>
    <h2>Novità: Possibilità di proporre foto tramite contest</h2>
    <h4>
      Abbiamo delle emozionanti novità da condividere con te! Ora hai la possibilità di essere parte attiva della nostra community su Roggia Fanpage. Vuoi condividere la tua passione per le rogge e i corsi d'acqua? Bene, abbiamo una fantastica opportunità per te.
    </h4>
    <h4>
      Se hai delle incredibili foto o momenti speciali legati alle rogge che desideri condividere con noi e con gli altri appassionati, puoi farlo tramite contest! 
    </h4>

    <h2>Ecco come puoi partecipare:</h2>
    <ul>
      <li>
        <strong>Su Instagram:</strong> Puoi contattare _roggia_fanpage_ su Instagram. Seguici, invia un messaggio diretto o menzionaci nelle tue foto legate alle rogge. Saremo felici di dare uno sguardo e potremmo condividerle con la nostra community.
      </li>
      <li>
        <strong>Per E-mail:</strong> Hai scatti spettacolari o momenti speciali che vuoi inviarci? Basta inviare un'email a richiesta@roggiafanpage.it con le tue foto e una breve descrizione di cosa rende quelle immagini così significative per te.
      </li>
    </ul>
    
    <p>
      Tra tutte le foto inviate, sarà solo una la folto prescelta  che verrà pubblicata nel profilo. Deve soddisfare di una buona qualità e non deve essere  di una roggia già presente nel profilo. 
    </p>
  </div>
</div>

  
  









  
  );
}

export default Proposta;