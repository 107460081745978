import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import img1 from './photos/image1.jpg'
import img2 from './compages/bucarest.jpg'
import img3 from './photos/image3.jpg'
import img4 from './photos/image4.jpg'
import img5 from './compages/casserena.jpg'
import img6 from './photos/image6.jpg'
import img7 from './photos/image7.jpg'
import image8 from './compages/Tandarei.jpg'
import image9 from './photos/image9.jpg'
import image10 from './compages/piccolini.jpg'
import image11 from './photos/image11.jpg'
import image12 from './compages/mais.jpg'

function ImageCarousel() {
  return (
    <div className='container' style={{ marginTop: '50px' }}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Carousel>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={img1}
              alt="Prima slide"
              style={{ height: '600px', objectFit: 'cover' }}
            />
            <Carousel.Caption>
              <h3>Roggia Gravellonese</h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={img2}
              alt="Seconda slide"
              style={{ height: '600px', objectFit: 'cover' }}
            />
            <Carousel.Caption>
              <h3>Roggia di Bucarest</h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
          <img
            className="d-block w-100"
            src={img3}
            alt="Terza slide"
            width={4000}
            height={600}
          />
           <Carousel.Caption>
              <h3>Roggia di via Riseria</h3>
            </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img4}
            alt="Terza slide"
            width={4000}
            height={600}
          />
           <Carousel.Caption>
              <h3>Roggia del depuratore</h3>
            </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img5}
            alt="Terza slide"
            width={4000}
            height={600}
          />
           <Carousel.Caption>
              <h3>Roggia retro-casa serena</h3>
            </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img6}
            alt="Terza slide"
            width={4000}
            height={600}
          />
           <Carousel.Caption>
              <h3>Canale Grande di Cilavegna</h3>
            </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img7}
            alt="Terza slide"
            width={4000}
            height={600}
          />
           <Carousel.Caption>
              <h3>Roggia di Mortara </h3>
            </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={image8}
            alt="Terza slide"
            width={4000}
            height={600}
          />
           <Carousel.Caption>
              <h3>Roggia di Tandarei</h3>
            </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={image9}
            alt="Terza slide"
            width={4000}
            height={600}
          />
           <Carousel.Caption>
              <h3>Roggia di Sant' Anna</h3>
            </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={image10}
            alt="Terza slide"
            width={4000}
            height={600}
          />
           <Carousel.Caption>
              <h3>Roggia di piccolini</h3>
            </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={image11}
            alt="Terza slide"
            width={4000}
            height={600}
          />
           <Carousel.Caption>
              <h3>Roggia di Borgolavezzaro</h3>
            </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={image12}
            alt="Terza slide"
            width={4000}
            height={600}
          />
           <Carousel.Caption>
              <h3>Vista sul lago Maggiore su Arona</h3>
            </Carousel.Caption>
        </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
}

export default ImageCarousel;



