import React from 'react';
import cclicense from './compages/cclicense.png';


function Terms() {
  return (
    <div className="container">
      <h1>Termini e condizioni</h1>
      <h2>MIT LICENCE FOR ALL USERS</h2>
      <h3>SITO WEB</h3>
      <p>ITA: IL CODICE SORGENTE DEL SITO WEB E' CONDIVISO PUBBICAMENTE SU GITHUB SOTTO LICENZA MIT. < br/>
        LISTA AZIONI CONSENTITE <  br/>
        ° MODIFICARE IL CODICE SORGENTE PER PRENDERE ISPIRAZIONE O PER SEGNALARE E FIOXARE EVENTUALI BUG DEL SERVIZIO. <br/>
        ° USO DEI CODICI PER CREARE CONTENUTI DA INTEGRARE AD ALTRI SERVIZI WEB. <br/>
        ° USO A SCOPO PERSONALE
        LISTA AZIONI PROIBITE <br/>
        ° MODIFICARE IL CODICE PER PRODOTTI COMMERCIALI. <br/>
        ° RIPUBBLICARE IL SITO. <br/>
        ° MODIFICARE IL CODICE PER SCOPI COMMERCIALI. <br/>
        ° MODIFICARE IL DIRITTO DEL SITO. <br/>
        TALI EVASIONI DELLE PROIBIZIONI POSSONO ESSERE SEGUITI PENALMENTE, CON SANZIONI MOLTO SALATE E DETENZIONE PER VIOLAZIONE DEL DIRITTO D'AUTORE. <br/>
     ENG: THE SOURCE CODE OF THE WEBSITE IS PUBLICLY SHARED ON GITHUB UNDER THE MIT LICENSE. < br/>
        ALLOWED ACTIONS LIST < br/>
        ° MODIFY THE SOURCE CODE TO GET INSPIRATION OR TO REPORT AND FIX ANY BUGS IN THE SERVICE. <br/>
        ° USE OF THE CODES TO CREATE CONTENT TO BE INTEGRATED WITH OTHER WEB SERVICES. <br/>
        ° USE FOR PERSONAL PURPOSE
        PROHIBITED ACTIONS LIST <br/>
        ° CHANGE THE CODE FOR COMMERCIAL PRODUCTS. <br/>
        ° REPUBLICATE THE SITE. <br/>
        ° MODIFY THE CODE FOR COMMERCIAL PURPOSES. <br/>
        ° CHANGE THE RIGHT OF THE SITE. <br/>
        SUCH ESCAPES OF THE PROHIBITIONS CAN BE ENSURED BY CRIMINAL LAWS, WITH VERY HEALTHY SANCTIONS AND IMPRISONMENT FOR COPYRIGHT INFRINGEMENT. <br/>
        </p>
        <h3>IMMAGINI E CONTENUTI MULTIMEDIALI</h3>
        <p>
          IT: LE IMMAGINI E I CONTENUTI MIUTIMEDIALI (VIDEO, STORIES, PROBABILI RENDER 3D), CONDIVISI SIA SUL SITO CHE SUI SOCIAL NETWORK, CONSERVANO LA LICENZA CC SENCONDO LE REGOLAMENTAZIONI VIGENTI: < br />
          - I CONTENUTI ESTRATTI DEVONO ESSERE UTLIZZATI SOLO PER SCOPI PERSONALI. IN CASO DI UTILIZZO A SCOPI COMMERCIALI E' NECCESARIO CITARE LA FONTE E CHIEDERE AUTORIZZAZIONI < br />
          - I CONTENTI ESTRATTI NON POSSONO ESSERE VENDUTI. < br />
          - I CONTENUTI ESTRATTI NON POSSONO ESSERE RIVENDICATI COME PROPRI. < br />
          - I CONTENUTI ESTRATTI POSSONO ESSERE UTILIZZATI NEL PROPRIO WEBSITE MA NON PER SCOPI COMMERCIALI. <br />
          - I CONTENUTI ESTRATTI NON POSSONO ESSERE MODIFICATI. < br />
          - I CONTENUTI ESTRATTI NON POSSONO ESSERE REPOSTATE IN ALTRI PROFILI DI SOCIAL MEDIA. <br />
          - PER I CONTENUTI ESTRATTI VI E' LA PARZIALE POSSIBILITA' DI ESTRARLA E POSTARE LA STORIA TAGGANDO IL PROFILO INSTAGRAM DI ROGGIA FANPAGE. (VALIDO SOLO PER IMMAGINI). < br/>
          OGNI VIOLAZIONE DI QUESTE NORME COMPORTA SANZIONI LEGALI. <br />
          ENG: THE SOURCE CODE OF THE WEBSITE IS PUBLICLY SHARED ON GITHUB UNDER THE MIT LICENSE. < br/>
        ALLOWED ACTIONS LIST < br/>
        ° MODIFY THE SOURCE CODE TO GET INSPIRATION OR TO REPORT AND FIX ANY BUGS IN THE SERVICE. <br/>
        ° USE OF THE CODES TO CREATE CONTENT TO BE INTEGRATED WITH OTHER WEB SERVICES. <br/>
        ° USE FOR PERSONAL PURPOSE
        PROHIBITED ACTIONS LIST <br/>
        ° CHANGE THE CODE FOR COMMERCIAL PRODUCTS. <br/>
        ° REPUBLICATE THE SITE. <br/>
        ° MODIFY THE CODE FOR COMMERCIAL PURPOSES. <br/>
        ° CHANGE THE RIGHT OF THE SITE. <br/>
        SUCH ESCAPES OF THE PROHIBITIONS CAN BE ENSURED BY CRIMINAL LAWS, WITH VERY HEALTHY SANCTIONS AND IMPRISONMENT FOR COPYRIGHT INFRINGEMENT. <br/>
        </p>
        <p>--------------------------------------------------------------------------------------------------------------------</p>
    <h3>Informativa sulla Privacy</h3>
    <p>Il tuo rispetto per la privacy è importante per noi. In conformità con il Regolamento Generale sulla Protezione dei Dati (GDPR), abbiamo implementato misure per garantire che i tuoi dati personali siano conservati in modo sicuro e responsabile.</p>

    <h3>Raccolta dei dati</h3>
    <p>Raccogliamo solo le informazioni necessarie per fornirti i nostri servizi. Questo può includere il tuo nome, indirizzo email, numero di telefono e altre informazioni pertinenti.</p>

    <h3>Conservazione dei dati</h3>
    <p>I tuoi dati personali saranno conservati solo per il tempo necessario per fornirti i servizi richiesti. Dopo questo periodo, i tuoi dati saranno cancellati in modo sicuro.</p>

    <h3>Sicurezza dei dati</h3>
    <p>Abbiamo implementato misure di sicurezza appropriate per proteggere i tuoi dati personali da accessi non autorizzati, uso improprio, divulgazione, perdita o distruzione. Queste misure includono protocolli di sicurezza fisici e digitali.</p>

    <h3>Diritti dell'interessato</h3>
    <p>Hai il diritto di accedere, correggere, cancellare i tuoi dati personali o limitare il loro trattamento. Puoi esercitare questi diritti in qualsiasi momento contattandoci.</p>

    <h3>Modifiche a questa informativa</h3>
    <p>Ci riserviamo il diritto di modificare questa informativa sulla privacy in qualsiasi momento. Eventuali modifiche saranno pubblicate su questa pagina.</p>
        <div class="text-center">
        <div class="text-center">
  <img src={cclicense} class="rounded" alt="..."></img>
</div>
</div>
    </div>
  );
}

export default Terms;


