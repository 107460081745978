import React from "react";
import Countdown from "./Countdown";

function Eventi() {
    return(
     <div className="Container"> 
     <h1>Eventi</h1>
     <Countdown />
     
     </div>
    );
}

export default Eventi;