import React from "react";
import NordVPN from './compages/nordvpn.png'
import Ngllogo from './compages/logo192.jpg'
import Itislogo from './compages/Designer.jpeg'
import { Container } from "react-bootstrap";

function Partner () {
    return(
        <div className="container">
            <Container>

            <h1>Altri nostri progetti
            </h1>
            
            <img src={Ngllogo}></img>
            <p>NGL CILA <br />
           Si occupa di informare Cilavgena e i paesi attorno, dei gossip, notizie e tant'altro <br />
            scopri di più visitando <a href="https://nglcila.com">nglcila.com</a> </p>
            <img src={Itislogo}></img>
            <p>Spotted del biennio (ITIS Caramuel) <br />
           Si occupa di fare gossip sul biennio dell'ITIS Caramuel di Vigevano (PV)<br />
            scopri di più visitando <a href="https://nglcila.com/itis">nglcila.com</a> </p>
         </Container>
            </div>
            
    );
}
export default Partner;