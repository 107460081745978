import React from "react";
import Content from "./Content";

import Photo from "./Photo";

import SocialButtons from "./SocialButtons";
import Ebody from "./Ebody";
import Proposta from "./Proposta";
import Separation from "./Separation";
import BootstrapCarousel from "./Carosello";
import ImageCarousel from "./Carosello";
import Partner from "./Partnership";
import InstagramFollowerCounter from "./Followercounter";
import ContestButton from "./Contest";
function Home () {
    return (
        <div className="Home">
      <Content />
      <Photo />
      <BootstrapCarousel />
      <Separation />
      <Ebody />
      <SocialButtons />
      <Separation  />
      <ContestButton />
      <Separation />
      <Partner />



      </div>
    );
}

export default Home;