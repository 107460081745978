import React from "react";
import Mappage from "./compages/mappage";


function Map() {
    return(
 <div>
      <h1>La mappa interattiva delle foto roggia</h1>
      <p>In questa mappa trovi tutte le rogge ed i loro vari posti</p>
      <Mappage />
      
 </div>
    );
}

export default Map;