import { auth, googleProvider, facebookProvider } from "./config/firebase";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup, signOut, onAuthStateChanged, FacebookAuthProvider, authentication } from "firebase/auth";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { ButtonGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

export const AuthPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    // Pulizia alla disconnessione
    return () => unsubscribe();
  }, []);

  const signIn = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      alert("Accesso effettuato con successo");
      navigate("/home");
    } catch (err){
      console.error(err);
    }
  };

  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      alert("Accesso effettuato con successo");
      navigate("/home");
    } catch (err){
      console.error(err);
    }
  };
  const signInWithFacebook = async () => {
    try {
      await signInWithPopup(auth, facebookProvider);
      alert("Accesso effettuato con successo");
      navigate("/home");
    } catch (err){
      console.error(err);
    }
  };
  

  
  
  
  
  

  const logOut = async () => {
    try {
      await signOut(auth);
      alert("Sei uscito dall'account");
      onAuthStateChanged(auth, (currentUser) => {
        setUser(currentUser);
      });
    } catch (err){
      console.error(err);
    }
  };


  return (
    <div style={{ padding: '50px 20%' }}>
      <h1 className="text-center">Accedi</h1>
      <Form className="border border-3 rounded p-3">
        <Form.Group className="mb-3" controlId="exampleInputEmail1">
          <Form.Label>Indirizzo E-Mail o nome utente</Form.Label>
          <Form.Control type="email" onChange={(e) => setEmail(e.target.value)} />
          <Form.Text className="text-muted">
            Questo campo è obbligatorio.
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleInputPassword1">
          <Form.Label>Password</Form.Label>
          <Form.Control type="password" onChange={(e) => setPassword(e.target.value)}/>
        </Form.Group>

        <ButtonGroup>
          <Button variant="primary" type="submit" onClick={signIn} style={{ marginRight: '10px' }}>
            Accedi
          </Button>
          <Button variant="primary" type="submit" href="/register" style={{ marginRight: '10px' }}>
            Registrati
          </Button>
          <Button variant="outline-primary" style={{borderRadius: '12px', marginRight: '10px'}} onClick={signInWithGoogle}>
            <img 
              src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg" 
              alt="Google Logo" 
              style={{width: '20px', marginRight: '10px'}}
            />
            Accedi con Google
          </Button>
          <Button variant="primary" type="submit" onClick={logOut}>
            Esci
          </Button>
          <br />
        <a href="/terms">I dati vengono conservati secondo GDPR, scopri di più</a>
        </ButtonGroup>


      </Form>
    </div>
  );
};




