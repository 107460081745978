import React from "react";
import DownloadButton from "./DownloadButton";

function Download() {
    return(
   <div>
    <h1>Download dei contenuti</h1>

    <DownloadButton />

   </div>
    );


}

export default Download;